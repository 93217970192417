export enum RoutePathsEnum {
  all = '*',
  empty = '',
  root = '/',
  contracts = 'contracts',
  notFound = '404',
  notAllowed = '405',
  internalServerError = '500',
  active = 'active',
  pending = 'pending',
  completed = 'completed',
  maintenance = 'maintenance',
  comingSoon = 'coming-soon',
  users = 'users'
}

export const navigateToLogin = () => {
  window.location.href = RoutePathsEnum.root;
};
