import {
  DashboardTwoTone,
  FindInPageTwoTone,
  FolderSharedTwoTone,
  NoteAddTwoTone,
  TaskTwoTone
} from '@mui/icons-material';
import { makeAutoObservable, reaction } from 'mobx';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePathsEnum } from 'src/helpers/navigation';
import { v4 } from 'uuid';
import { GlobalStore } from '../global';
import { UserStore } from '../user';
import {
  ActiveRoutesType,
  ModulesType,
  PrivateRoutesType,
  PrivateRouteType,
  PublicRoutesType
} from './types';

const Login = lazy(() => import('src/modules/login/layout'));
const Home = lazy(() => import('src/modules/home/layout'));
const PendingContracts = lazy(
  () => import('src/modules/contracts/pending/layout')
);
const ActiveContracts = lazy(
  () => import('src/modules/contracts/active/layout')
);
const CompletedContracts = lazy(
  () => import('src/modules/contracts/completed/layout')
);
const Users = lazy(() => import('src/modules/users/layout'));
const ModuleRoutesPage = lazy(
  () => import('src/components/moduleRoutesPage/layout')
);
const StatusPage = lazy(() => import('src/components/statusPage/layout'));

export default class RouterStore {
  userStore: UserStore;

  modules: ModulesType;

  activeRoutes: ActiveRoutesType;

  homeRoute: PrivateRouteType;
  privateRoutes: PrivateRoutesType;
  publicRoutes: PublicRoutesType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.userStore = globalStore.UserStore;

    const { contracts, empty } = RoutePathsEnum;

    this.modules = {
      contracts: { name: 'Contratos', path: contracts }
    };

    this.activeRoutes = null;

    this.homeRoute = {
      title: 'Dashboard',
      path: empty,
      children: <Home />,
      pageSubtitle:
        'Explore dados dos clientes, contratos, garantias, agendas de recebíveis e mais.',
      icon: <DashboardTwoTone />,
      isMenu: true
    };
    this.publicRoutes = [];
    this.privateRoutes = [];

    this.handleRoutes(this.userStore.userSession);

    reaction(
      () => this.userStore.userSession,
      () => {
        this.handleRoutes(this.userStore.userSession);
      }
    );
  }

  handleRoutes = (userSession: UserStore['userSession']) => {
    const {
      all,
      empty,
      root,
      comingSoon,
      internalServerError,
      active,
      completed,
      pending,
      maintenance,
      notAllowed,
      notFound,
      users
    } = RoutePathsEnum;

    const handlePrivateRoutesProps = (routes: PrivateRoutesType) =>
      routes.map(
        ({
          hasViewPermission = true,
          children,
          module,
          path,
          routeId = v4(),
          ...item
        }) =>
          ({
            ...item,
            module,
            hasViewPermission,
            routeId,
            children: hasViewPermission ? (
              children
            ) : (
              <Navigate to={`/${notAllowed}`} replace />
            ),
            path:
              module !== undefined
                ? `${this.modules[module].path}${path ? `/${path}` : ''}`
                : path
          } as PrivateRouteType)
      );

    const publicRoutes: PublicRoutesType = [
      {
        children: <Login />,
        path: empty,
        title: 'Zak Register',
        routeTitle: 'Login',
        pageSubtitle:
          'Plataforma destinada ao controle interno de cobranças por  meio de recebíveis de cartão de crédito.'
      },
      {
        path: all,
        children: <Navigate to={root} replace />
      }
    ];

    const privateRoutes: PrivateRoutesType = [
      this.homeRoute,
      {
        title: 'Acesso negado',
        pageSubtitle: (
          <>
            Desculpe, mas você não possui permissão para acessar essa página.
            <br />
            Por favor, consulte um administrador do sistema para revisão do seu
            nível de acesso.
          </>
        ),
        path: notAllowed,
        children: <StatusPage image="/images/405.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Página não encontrada',
        pageSubtitle: (
          <>
            Desculpe, mas não conseguimos encontrar a página que você está
            procurando.
            <br />
            Por favor, reveja o endereço informado ou volte para nossa home.
          </>
        ),
        path: notFound,
        children: <StatusPage image="/images/404.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Aconteceu algum erro',
        pageSubtitle: (
          <>
            O servidor encontrou um erro interno e não conseguiu completar sua
            requisição.
            <br />
            Por favor, tente novamente mais tarde ou volte para nossa home.
          </>
        ),
        path: internalServerError,
        children: <StatusPage image="/images/500.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Página em Manutenção',
        pageSubtitle: (
          <>
            Já estamos trabalhando o mais rápido possível para voltar a
            normalidade.
            <br />
            Por favor, tente novamente mais tarde ou volte para nossa home.
          </>
        ),
        path: maintenance,
        children: <StatusPage image="/images/maintenance.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Em breve!',
        pageSubtitle: (
          <>
            Estamos trabalhando para implementar as últimas funcionalidades para
            o lançamento.
            <br />
            Por favor, tente novamente mais tarde ou volte para nossa home.
          </>
        ),
        path: comingSoon,
        children: <StatusPage image="/images/coming-soon.svg" />,
        vPosition: 'center'
      },
      {
        module: 'contracts',
        path: empty,
        hasViewPermission: userSession?.permissions?.contracts.view,
        title: 'Contratos',
        children: <ModuleRoutesPage module="contracts" />,
        breadcrumbs: true
      },
      {
        module: 'contracts',
        title: 'Pendentes',
        routeTitle: 'Contratos Pendentes',
        pageTitle: 'Contratos Pendentes',
        pageSubtitle: 'Emita garantias em novos contratos.',
        icon: <NoteAddTwoTone />,
        path: pending,
        hasViewPermission: userSession?.permissions?.contracts.view,
        children: <PendingContracts />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'contracts',
        title: 'Ativos',
        routeTitle: 'Contratos Ativos',
        pageTitle: 'Contratos Ativos',
        pageSubtitle: 'Monitore as garantias recebidas em contratos ativos.',
        icon: <FindInPageTwoTone />,
        path: active,
        hasViewPermission: userSession?.permissions?.contracts.view,
        children: <ActiveContracts />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'contracts',
        title: 'Finalizados',
        routeTitle: 'Contratos Finalizados',
        pageTitle: 'Contratos Finalizados',
        pageSubtitle:
          'Consulte o histórico de garantias em contratos finalizados.',
        icon: <TaskTwoTone />,
        path: completed,
        hasViewPermission: userSession?.permissions?.contracts.view,
        children: <CompletedContracts />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        path: users,
        icon: <FolderSharedTwoTone />,
        title: 'Usuários do Sistema',
        pageSubtitle: 'Controle e gerenciamento de accesso ao sistema.',
        hasViewPermission: userSession?.permissions?.users,
        children: <Users />,
        isMenu: true,
        breadcrumbs: true
      },
      {
        path: all,
        children: <Navigate to={notFound} replace />
      }
    ];

    this.publicRoutes = publicRoutes;
    this.privateRoutes = handlePrivateRoutesProps(privateRoutes);

    this.activeRoutes = !!userSession ? 'private' : 'public';
  };
}
