import { action, makeAutoObservable } from 'mobx';
import { MerchantsFiltersType } from 'src/modules/home/types';
import { GlobalStore } from '../global';
import { MerchantsType } from './types';

export default class MerchantStore {
  merchants: MerchantsType;

  filters: MerchantsFiltersType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);

    this.merchants = [];

    this.filters = {
      page: 1,
      pageSize: 10,
      cnpj: '',
      statusRegister: null,
      legalName: '',
      merchantId: ''
    };
  }

  @action.bound
  setFiltersProps = (value: MerchantsFiltersType) => {
    this.filters = value;
  };
}
