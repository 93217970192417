import { action, makeAutoObservable } from 'mobx';
import { GlobalStore } from '../global';
import { ContractFilterType, ContractsFiltersType } from './types';

export default class ContractsStore {
  filters: ContractsFiltersType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    const filter: ContractFilterType = {
      merchant: null,
      chargeId: '',
      dueDate: null,
      creationDate: null,
      page: 1,
      pageSize: 10
    };

    this.filters = {
      ACTIVE: filter,
      PENDING: filter,
      COMPLETED: filter
    };
  }

  @action.bound
  setFiltersProps = (
    status: keyof ContractsFiltersType,
    value: ContractFilterType
  ) => {
    this.filters[status] = value;
  };
}
