import { Breadcrumbs } from '@mui/material';
import { RouteHelmet } from 'src/components/router/routeHelmet';
import { RouteLoader } from 'src/components/router/routeLoader';
import { withStores } from 'src/stores';
import { Styled } from './styles';
import { PrivateRouteProps } from './types';

export const PrivateRoute = withStores<PrivateRouteProps>(
  ['RouterStore'],
  ({
    path,
    RouterStore,
    breadcrumbs,
    children,
    module,
    routeHelmetProps,
    routeTitle,
    pageSubtitle,
    pageTitle,
    title,
    vPosition
  }: RequiredProp<PrivateRouteProps, 'RouterStore'>) => {
    const { homeRoute, modules } = RouterStore;

    const isModuleRoot = !path.includes('/');

    const handleLinkHref = (path: string) => `/${path}`;

    const handleLinkTitle = (title: string) => `Navegar para ${title}`;

    return (
      <>
        <RouteHelmet {...routeHelmetProps} title={routeTitle ?? title ?? ''} />
        <Styled.Container maxWidth="xl">
          <Styled.Head vPosition={vPosition}>
            {breadcrumbs && (
              <Breadcrumbs separator={<Styled.BreadcrumbsSeparator />}>
                <Styled.Link
                  title={
                    homeRoute?.title
                      ? handleLinkTitle(homeRoute.title)
                      : undefined
                  }
                  to={handleLinkHref(homeRoute.path)}
                >
                  {(!module || isModuleRoot) && <Styled.BackIcon />}
                  <Styled.BreadcrumbsText>
                    {homeRoute.title}
                  </Styled.BreadcrumbsText>
                </Styled.Link>
                {module && !isModuleRoot && (
                  <Styled.Link
                    title={handleLinkTitle(modules[module]?.name)}
                    to={handleLinkHref(modules[module]?.path)}
                  >
                    <Styled.BreadcrumbsText>
                      {modules[module]?.name}
                    </Styled.BreadcrumbsText>
                  </Styled.Link>
                )}
              </Breadcrumbs>
            )}
            <Styled.Text variant="h1" vPosition={vPosition}>
              {pageTitle ?? title}
            </Styled.Text>
            {pageSubtitle && (
              <Styled.Text
                variant="subtitle2"
                component="p"
                vPosition={vPosition}
              >
                {pageSubtitle}
              </Styled.Text>
            )}
          </Styled.Head>
          <Styled.Content vPosition={vPosition}>
            <RouteLoader>{children}</RouteLoader>
          </Styled.Content>
        </Styled.Container>
      </>
    );
  }
);
