interface RegistersType extends Array<{ document: string; name: string }> {}

export const paymentArrangements: string[] = [
  'ECC',
  'MCC',
  'VCD',
  'MCD',
  'VCC',
  'ECD',
  'ACC',
  'ACD'
];

export const registers: RegistersType = [
  { document: '23399607000191', name: 'CERC' },
  { document: '31345107000103', name: 'Tag' },
  { document: '04391007000132', name: 'CIP' },
  { document: '09346601000125', name: 'B3' }
];
