import { RoutePathsEnum } from 'src/helpers/navigation';
import packageJson from '../../../package.json';
import { Styled } from './styles';
import { LogoProps } from './types';

export const Logo = ({
  variant = 'default',
  to = RoutePathsEnum.root,
  ...props
}: LogoProps) => {
  const logos = {
    default: 'zak-logo.svg',
    white: 'zak-logo-white.svg'
  };

  const logo = logos[variant];

  return (
    <Styled.Container {...props} to={to}>
      <Styled.Version
        overlap="circular"
        color="primary"
        badgeContent={packageJson.version}
      >
        <Styled.Image src={`/images/${logo}`} alt="Zak" />
      </Styled.Version>
    </Styled.Container>
  );
};
