import { action, makeAutoObservable } from 'mobx';
import {
  decodeToken,
  getToken,
  removeTokens
} from 'src/helpers/cookies/auth_user';
import { formatUserRoles } from 'src/helpers/masks';
import { GlobalStore } from '../global';
import { RolePermissionsType, UserSessionType } from './types';

export default class UserStore {
  userSession: UserSessionType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);

    const userSessionToken = getToken();
    this.userSession = null;

    if (userSessionToken) {
      this.setUserSession(userSessionToken);
    } else {
      this.resetUserSession();
    }
  }

  @action.bound
  handleLogout = async () => {
    this.resetUserSession();
    removeTokens();
  };

  @action.bound
  resetUserSession = () => {
    this.userSession = null;
  };

  @action.bound
  setUserSession = (token: string) => {
    const {
      user: { login, email, id, roles }
    } = decodeToken(token);

    const rolePermissions: RolePermissionsType = {
      admin: {
        merchants: { optIn: true, optOut: true, register: true },
        contracts: {
          view: true,
          edit: true
        },
        users: true
      },
      finance: {
        contracts: {
          view: true,
          edit: true
        },
        users: false,
        merchants: {
          optIn: true,
          optOut: true,
          register: true
        }
      },
      support: {
        contracts: {
          view: true,
          edit: false
        },
        merchants: { optIn: false, optOut: false, register: false },
        users: false
      }
    };

    const currentRoles = roles.map(({ name }) => name);

    this.userSession = {
      login,
      email,
      id,
      roles: currentRoles,
      formattedRoles: formatUserRoles(currentRoles),
      permissions: rolePermissions[roles[0].name]
    };
  };
}
