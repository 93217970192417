import { ContractsStore } from '../contracts';
import { LayoutStore } from '../layout';
import { MerchantStore } from '../merchant';
import { RouterStore } from '../router';
import { UserStore } from '../user';

export default class GlobalStore {
  RouterStore: RouterStore;
  LayoutStore: LayoutStore;
  UserStore: UserStore;
  MerchantStore: MerchantStore;
  ContractsStore: ContractsStore;

  constructor() {
    this.LayoutStore = new LayoutStore(this);
    this.UserStore = new UserStore(this);
    this.RouterStore = new RouterStore(this);
    this.MerchantStore = new MerchantStore(this);
    this.ContractsStore = new ContractsStore(this);
  }
}
