import { Outlet, RouteObject, useRoutes } from 'react-router';
import { RoutePathsEnum } from 'src/helpers/navigation';
import { withStores } from 'src/stores';
import { PrivateRoute } from './privateRoute';
import { Header } from './privateRoute/header';
import { Sidebar } from './privateRoute/sidebar';
import { PublicRoute } from './publicRoute';
import { Styled } from './styles';
import { RouterProps } from './types';

export const Router = withStores(
  ['RouterStore'],
  ({ RouterStore }: Required<RouterProps>) => {
    const { activeRoutes, privateRoutes, publicRoutes } = RouterStore;

    if (!activeRoutes) {
      return useRoutes([]);
    }

    const isActivePrivateRoutes = activeRoutes === 'private';
    const routes = isActivePrivateRoutes ? privateRoutes : publicRoutes;

    const routeObjects: RouteObject[] = routes.map(
      ({ path, routeId, children, ...props }) => ({
        path: RoutePathsEnum.empty,
        element: (
          <>
            {isActivePrivateRoutes && (
              <>
                <Header routeId={routeId} />
                <Sidebar />
              </>
            )}
            <Styled.Container
              component="main"
              isActivePrivateRoutes={isActivePrivateRoutes}
            >
              <Outlet />
            </Styled.Container>
          </>
        ),
        children: [
          {
            element: isActivePrivateRoutes ? (
              <PrivateRoute {...props} path={path}>
                {children}
              </PrivateRoute>
            ) : (
              <PublicRoute {...props} path={path}>
                {children}
              </PublicRoute>
            ),
            path
          }
        ]
      })
    );

    return useRoutes(routeObjects);
  }
);
