import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ptBr from 'date-fns/locale/pt-BR';
import { Provider } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { BackdropPage } from './components/backdropPage';
import { Router } from './components/router';
import { GlobalStore } from './stores/global';
import ThemeContainer from './theme';

const globalStore: GlobalStore = new GlobalStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 3
    },
    mutations: {
      retry: false
    }
  }
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider
        LayoutStore={globalStore.LayoutStore}
        UserStore={globalStore.UserStore}
        RouterStore={globalStore.RouterStore}
        MerchantStore={globalStore.MerchantStore}
        ContractsStore={globalStore.ContractsStore}
      >
        <HelmetProvider>
          <BrowserRouter>
            <ThemeContainer>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBr}
              >
                <SnackbarProvider maxSnack={5}>
                  <CssBaseline />
                  <BackdropPage />
                  <Router />
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeContainer>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
