import { Buffer } from 'buffer';
import Cookies from 'js-cookie';
import { PostUserLoginResponseType } from 'src/services/user';

const tokenKey = 'zak_register_auth_token';
const refreshTokenKey = 'zak_register_refresh_auth_token';

export type DecodedTokenRoleNameType = 'admin' | 'finance' | 'support';

export interface DecodedTokenRoleType {
  id: string;
  name: DecodedTokenRoleNameType;
  allows: Array<{
    rule: string;
    methods: string[];
  }>;
  denies: any[];
  createdAt: string;
  updatedAt: string;
  application: {
    id: string;
  };
}

interface DecodedTokenType {
  user: {
    id: string;
    login: string;
    email: string;
    userData: {
      fullName: string;
    };
    roles: DecodedTokenRoleType[];
    application: {
      id: string;
    };
    kind: string;
  };
  _id: string;
  refresh_token: boolean;
  iat: number;
  exp: number;
}

const getCookie = (key: string): string | null => {
  const cookie = Cookies.get(key);

  if (!cookie) {
    return null;
  }

  return cookie;
};

const saveCookie = (key: string, value: string, expires: number) => {
  const expirationTime = new Date(new Date().getTime() + expires * 1000);

  Cookies.set(key, value, { expires: expirationTime });
};

const saveTokens = ({
  token,
  expires_in,
  refresh_expires_in,
  refresh_token
}: PostUserLoginResponseType) => {
  // expires_in = 120; // Mock

  saveCookie(tokenKey, token, expires_in);
  saveCookie(refreshTokenKey, refresh_token, refresh_expires_in);
};

const removeTokens = () => {
  Cookies.remove(tokenKey);
  Cookies.remove(refreshTokenKey);
};

const decodeToken = (token: string): DecodedTokenType =>
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii'));

const getToken = (): string | null => getCookie(tokenKey);

const getRefreshToken = (): string | null => getCookie(refreshTokenKey);

export { decodeToken, getRefreshToken, getToken, removeTokens, saveTokens };
