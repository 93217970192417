import { isAfter, isBefore, isEqual } from 'date-fns';

export const isEqualOrAfter = (
  date: number | Date,
  dateToCompare: number | Date
) => isAfter(date, dateToCompare) || isEqual(date, dateToCompare);

export const isEqualOrBefore = (
  date: number | Date,
  dateToCompare: number | Date
) => isBefore(date, dateToCompare) || isEqual(date, dateToCompare);

export const validateOnlyNumber = (text: string) => text.replace(/\D/g, '');

export const isTodayDate = (date: string | number | Date) => {
  const formatDate = (date: string | number | Date) =>
    new Date(date).toISOString().slice(0, 10);

  return formatDate(new Date()) === formatDate(date);
};

export const isEmptyString = (str?: string) => !str;

export const validateMinLength = (value: string, minLength: number) =>
  value.length >= minLength;

export const isValidCnpj = (cnpj: string) =>
  validateMinLength(validateOnlyNumber(cnpj), 14);

export const isValidUUIDv4 = (uuid: string) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return regex.test(uuid);
};
