import { Theme } from '@mui/material';
import { action, makeAutoObservable } from 'mobx';
import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';
import { GlobalStore } from '../global';
import { LayoutThemesType } from './types';

export default class LayoutStore {
  pageLoader: boolean;
  sidebarVisibility: boolean;
  theme: Theme;
  themes: LayoutThemesType = {
    PureLightTheme
  };

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.pageLoader = false;
    this.sidebarVisibility = false;
    this.theme =
      this.themes[localStorage.getItem('appTheme') || 'PureLightTheme'];
  }

  @action.bound
  toggleSidebar = () => {
    this.sidebarVisibility = !this.sidebarVisibility;
  };

  @action.bound
  closeSidebar = () => {
    this.sidebarVisibility = false;
  };

  @action.bound
  showPageLoader = () => {
    this.pageLoader = true;
  };

  @action.bound
  hidePageLoader = () => {
    this.pageLoader = false;
  };

  @action.bound
  setTheme = (theme: string) => {
    localStorage.setItem('appTheme', theme);
    this.theme = this.themes[theme];
  };
}
