import { CircularProgress } from '@mui/material';
import { withStores } from 'src/stores';
import { Styled } from './styles';
import { BackdropPageProps } from './types';

export const BackdropPage = withStores(
  ['LayoutStore'],
  ({ LayoutStore }: Required<BackdropPageProps>) => (
    <Styled.Container open={LayoutStore.pageLoader}>
      <CircularProgress color="primary" />
    </Styled.Container>
  )
);
